import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import intersection from 'lodash/intersection';

import type { IOrdinoAppState, IWorkbench } from './interfaces';
import type { GlobalQuery } from './reprovisynApi';
import { viewsReducers } from './viewsReducer';
import { addColumn, setWorkbenchData, workbenchReducers } from './workbenchReducer';
import { scoreDataErrorNotification } from '../scores/utils';
import type { IOrdinoColumnDesc } from './types/column';

const initialState: IOrdinoAppState = {
  workbenches: [],
  focusWorkbenchIndex: 0,
  midTransition: false,
  isAnimating: false,
  globalQuery: {
    name: '',
    availableCategories: [],
    defaultCategories: [],
  },
  currentSessionId: '',
};

const ordinoSlice = createSlice({
  name: 'ordino',
  initialState,
  reducers: {
    ...viewsReducers,
    ...workbenchReducers,
    // TODO in general: does it make sense to group the reducer functions (e.g., by workbench, views, ...)? or even create multiple variables that are spread-in here.
    addEntityFormatting(state, action: PayloadAction<{ workbenchIndex: number; formatting: IWorkbench['formatting'] }>) {
      const { workbenchIndex, formatting } = action.payload;
      state.workbenches[workbenchIndex].formatting = formatting;
    },
    changeFocus(state: IOrdinoAppState, action: PayloadAction<{ index: number }>) {
      state.focusWorkbenchIndex = action.payload.index;
      state.workbenches[action.payload.index].collapsed = false;
      state.midTransition = false;
      state.isAnimating = true;
    },
    setTransition(state, action: PayloadAction<boolean>) {
      state.midTransition = action.payload;
    },
    setAnimating(state, action: PayloadAction<boolean>) {
      state.isAnimating = action.payload;
    },
    setAppliedCategories(state, action: PayloadAction<{ appliedCategories: GlobalQuery['appliedCategories'] }>) {
      const includesCurrentFilter =
        state.globalQuery.appliedCategories?.length === intersection(state.globalQuery.appliedCategories, action.payload.appliedCategories)?.length; // if no filter is selected then the new data is a superset of the previous data

      if (!includesCurrentFilter) {
        // if the new filter does not include the previous, remove all workbenches except the first one
        if (state.workbenches.length > 1) {
          state.workbenches.length = 1;
          state.focusWorkbenchIndex = 0;
          state.midTransition = false;
          state.isAnimating = true;
        }
        state.workbenches[0].selection = []; // clear the selection
      }
      state.globalQuery.appliedCategories = action.payload.appliedCategories;
    },
    resetOrdinoStore: () => initialState,
    setCurrentSessionId(state, action: PayloadAction<string>) {
      state.currentSessionId = action.payload;
    },
    resetSession: (state) => {
      state.currentSessionId = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addColumn.pending, (state, action) => {
      const { workbenchIndex, id, desc } = action.meta.arg;
      const isColumnPresent = state.workbenches[workbenchIndex]?.columnDescs.some((col) => col.uniqueId === id);
      if (isColumnPresent) {
        return;
      }

      // TODO: The CIME scores don't have a desc here, as it is part of the plugin...
      if (desc) {
        state.workbenches[workbenchIndex]?.columnDescs.push({
          ...desc,
          isLoading: true,
          dataMap: {},
          uniqueId: id,
        });
      }
    });
    builder.addCase(addColumn.fulfilled, (state, action) => {
      const { workbenchIndex, id } = action.meta.arg;
      const { desc, data } = action.payload;

      const dataMap = Object.fromEntries(data.map((row) => [row.id, row]));
      const workbench = state.workbenches[workbenchIndex]!;
      const index = workbench.columnDescs.findIndex((col) => col.uniqueId === id);
      const newColumnDesc: IOrdinoColumnDesc = { ...desc, dataMap, isLoading: false };
      if (index === -1) {
        workbench?.columnDescs.push(newColumnDesc);
      } else {
        workbench?.columnDescs.splice(index, 1, newColumnDesc);
      }
    });
    builder.addCase(addColumn.rejected, (state, action) => {
      console.error(action.error);
      const { workbenchIndex, id, desc } = action.meta.arg;
      scoreDataErrorNotification(action.error, desc?.label || id);

      // remove column from data if it was not successfully loaded
      const index = state.workbenches[workbenchIndex]!.columnDescs.findIndex((col) => col.uniqueId === id);
      state.workbenches[workbenchIndex]?.columnDescs.splice(index, 1);
    });
    builder.addCase(setWorkbenchData.pending, (state, action) => {
      const { workbenchIndex } = action.meta.arg;
      const workbench = state.workbenches[workbenchIndex];
      workbench!.isLoadingFilteredData = true;
    });
    builder.addCase(setWorkbenchData.fulfilled, (state, action) => {
      const { workbenchIndex, fullData, dataMap, columnData } = action.payload;
      const workbench = state.workbenches[workbenchIndex]!;
      workbench.filteredData = [];
      workbench.isLoadingFilteredData = true;
      workbench.data = fullData!;
      workbench.dataMap = dataMap!;
      workbench.dataLength = fullData!.length;
      const columnIds = Object.keys(columnData);
      workbench?.columnDescs.forEach((col) => {
        if (columnIds.includes(col.uniqueId)) {
          col.dataMap = columnData[col.uniqueId]!;
          col.isLoading = false;
        }
      });
    });
    builder.addCase(setWorkbenchData.rejected, (state, action) => {
      console.error(action.error);
    });
  },
});

export const {
  addView,
  changeSelectedMappings,
  setOpenSidebar,
  setCreateNextWorkbenchSidebarOpen,
  setViewParameters,
  createColumnDescs,
  addColumnDesc,
  removeColumnDesc,
  removeColumnDescs,
  updateColumnDesc,
  batchUpdateColumnDescs,
  removeView,
  replaceWorkbench,
  removeWorkbench,
  removeWorkbenchOnEntityDelete,
  setCollapsed,
  setMidTransition,
  addEntityFormatting,
  addSelection,
  addFilter,
  removeAllFilters,
  setWorkbenchFilter,
  setWorkbenchNamedIdSet,
  setWorkbenchDataLoading,
  setWorkbenchFilteredData,
  changeFocus,
  addFirstWorkbench,
  addWorkbench,
  setWorkbenchDirection,
  setTransition,
  setAnimating,
  setAppliedCategories,
  resetOrdinoStore,
  setCurrentSessionId,
  resetSession,
} = ordinoSlice.actions;

export const ordinoReducer = ordinoSlice.reducer;
