import { visynApi as api } from './visynBaseApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLoginApiLoginGet: build.query<GetLoginApiLoginGetApiResponse, GetLoginApiLoginGetApiArg>({
      query: () => ({ url: `/api/login` }),
    }),
    postLoginApiLoginPost: build.mutation<PostLoginApiLoginPostApiResponse, PostLoginApiLoginPostApiArg>({
      query: (queryArg) => ({ url: `/api/login`, method: 'POST', body: queryArg.bodyPostLoginApiLoginPost }),
    }),
    logoutApiLogoutPost: build.mutation<LogoutApiLogoutPostApiResponse, LogoutApiLogoutPostApiArg>({
      query: () => ({ url: `/api/logout`, method: 'POST' }),
    }),
    loggedinasApiLoggedinasGet: build.query<LoggedinasApiLoggedinasGetApiResponse, LoggedinasApiLoggedinasGetApiArg>({
      query: () => ({ url: `/api/loggedinas` }),
    }),
    loggedinasApiLoggedinasPost: build.mutation<LoggedinasApiLoggedinasPostApiResponse, LoggedinasApiLoggedinasPostApiArg>({
      query: () => ({ url: `/api/loggedinas`, method: 'POST' }),
    }),
    storesApiSecurityStoresGet: build.query<StoresApiSecurityStoresGetApiResponse, StoresApiSecurityStoresGetApiArg>({
      query: () => ({ url: `/api/security/stores` }),
    }),
    listIdtypesApiIdtypeGet: build.query<ListIdtypesApiIdtypeGetApiResponse, ListIdtypesApiIdtypeGetApiArg>({
      query: () => ({ url: `/api/idtype/` }),
    }),
    mapsToApiIdtypeIdtypeGet: build.query<MapsToApiIdtypeIdtypeGetApiResponse, MapsToApiIdtypeIdtypeGetApiArg>({
      query: (queryArg) => ({ url: `/api/idtype/${queryArg.idtype}/` }),
    }),
    mappingToApiIdtypeIdtypeToIdtypeGet: build.query<MappingToApiIdtypeIdtypeToIdtypeGetApiResponse, MappingToApiIdtypeIdtypeToIdtypeGetApiArg>({
      query: (queryArg) => ({ url: `/api/idtype/${queryArg.idtype}/${queryArg.toIdtype}/`, body: queryArg.idTypeMappingRequest }),
    }),
    mappingToApiIdtypeIdtypeToIdtypePost: build.mutation<MappingToApiIdtypeIdtypeToIdtypePostApiResponse, MappingToApiIdtypeIdtypeToIdtypePostApiArg>({
      query: (queryArg) => ({ url: `/api/idtype/${queryArg.idtype}/${queryArg.toIdtype}/`, method: 'POST', body: queryArg.idTypeMappingRequest }),
    }),
    mappingToSearchApiIdtypeIdtypeToIdtypeSearchGet: build.query<
      MappingToSearchApiIdtypeIdtypeToIdtypeSearchGetApiResponse,
      MappingToSearchApiIdtypeIdtypeToIdtypeSearchGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/idtype/${queryArg.idtype}/${queryArg.toIdtype}/search/`, body: queryArg.idTypeMappingSearchRequest }),
    }),
    getPluginsApiTdpPluginGet: build.query<GetPluginsApiTdpPluginGetApiResponse, GetPluginsApiTdpPluginGetApiArg>({
      query: () => ({ url: `/api/tdp/plugin` }),
    }),
    xlsx2JsonApiXlsxToJsonPost: build.mutation<Xlsx2JsonApiXlsxToJsonPostApiResponse, Xlsx2JsonApiXlsxToJsonPostApiArg>({
      query: (queryArg) => ({ url: `/api/xlsx/to_json/`, method: 'POST', body: queryArg.bodyXlsx2JsonApiXlsxToJsonPost }),
    }),
    xlsx2JsonArrayApiXlsxToJsonArrayPost: build.mutation<Xlsx2JsonArrayApiXlsxToJsonArrayPostApiResponse, Xlsx2JsonArrayApiXlsxToJsonArrayPostApiArg>({
      query: (queryArg) => ({ url: `/api/xlsx/to_json_array/`, method: 'POST', body: queryArg.bodyXlsx2JsonArrayApiXlsxToJsonArrayPost }),
    }),
    json2XlsxApiXlsxFromJsonPost: build.mutation<Json2XlsxApiXlsxFromJsonPostApiResponse, Json2XlsxApiXlsxFromJsonPostApiArg>({
      query: (queryArg) => ({ url: `/api/xlsx/from_json/`, method: 'POST', body: queryArg.tableData }),
    }),
    jsonArray2XlsxApiXlsxFromJsonArrayPost: build.mutation<JsonArray2XlsxApiXlsxFromJsonArrayPostApiResponse, JsonArray2XlsxApiXlsxFromJsonArrayPostApiArg>({
      query: (queryArg) => ({ url: `/api/xlsx/from_json_array/`, method: 'POST', body: queryArg.data }),
    }),
    buildInfoBuildInfoJsonGet: build.query<BuildInfoBuildInfoJsonGetApiResponse, BuildInfoBuildInfoJsonGetApiArg>({
      query: () => ({ url: `/buildInfo.json` }),
    }),
    healthApiHealthGet: build.query<HealthApiHealthGetApiResponse, HealthApiHealthGetApiArg>({
      query: () => ({ url: `/api/health` }),
    }),
    healthApiHealthHead: build.mutation<HealthApiHealthHeadApiResponse, HealthApiHealthHeadApiArg>({
      query: () => ({ url: `/api/health`, method: 'HEAD' }),
    }),
    deprecatedHealthHealthGet: build.query<DeprecatedHealthHealthGetApiResponse, DeprecatedHealthHealthGetApiArg>({
      query: () => ({ url: `/health` }),
    }),
    deprecatedHealthHealthHead: build.mutation<DeprecatedHealthHealthHeadApiResponse, DeprecatedHealthHealthHeadApiArg>({
      query: () => ({ url: `/health`, method: 'HEAD' }),
    }),
    subscribeApiV1InsightSubscribeGet: build.query<SubscribeApiV1InsightSubscribeGetApiResponse, SubscribeApiV1InsightSubscribeGetApiArg>({
      query: () => ({ url: `/api/v1/insight/subscribe` }),
    }),
    getCampaignsApiV1InsightCampaignsGet: build.query<GetCampaignsApiV1InsightCampaignsGetApiResponse, GetCampaignsApiV1InsightCampaignsGetApiArg>({
      query: () => ({ url: `/api/v1/insight/campaigns` }),
    }),
    getCampaignApiV1InsightCampaignIdGet: build.query<GetCampaignApiV1InsightCampaignIdGetApiResponse, GetCampaignApiV1InsightCampaignIdGetApiArg>({
      query: (queryArg) => ({ url: `/api/v1/insight/campaign/${queryArg.id}` }),
    }),
    deleteCampaignApiV1InsightCampaignIdDelete: build.mutation<
      DeleteCampaignApiV1InsightCampaignIdDeleteApiResponse,
      DeleteCampaignApiV1InsightCampaignIdDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/insight/campaign/${queryArg.id}`, method: 'DELETE' }),
    }),
    updateCampaignApiV1InsightCampaignIdPatch: build.mutation<
      UpdateCampaignApiV1InsightCampaignIdPatchApiResponse,
      UpdateCampaignApiV1InsightCampaignIdPatchApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/insight/campaign/${queryArg.id}`, method: 'PATCH', body: queryArg.campaignUpdate }),
    }),
    createCampaignApiV1InsightCampaignPost: build.mutation<CreateCampaignApiV1InsightCampaignPostApiResponse, CreateCampaignApiV1InsightCampaignPostApiArg>({
      query: (queryArg) => ({ url: `/api/v1/insight/campaign`, method: 'POST', body: queryArg.campaignCreate }),
    }),
    addCandidatesApiV1InsightCampaignIdAddCandidatesPost: build.mutation<
      AddCandidatesApiV1InsightCampaignIdAddCandidatesPostApiResponse,
      AddCandidatesApiV1InsightCampaignIdAddCandidatesPostApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/insight/campaign/${queryArg.id}/add_candidates`, method: 'POST', body: queryArg.candidates }),
    }),
    getAnswersApiV1InsightAnswerQuestionIdGet: build.query<
      GetAnswersApiV1InsightAnswerQuestionIdGetApiResponse,
      GetAnswersApiV1InsightAnswerQuestionIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/insight/answer/${queryArg.questionId}`, params: { candidate_id: queryArg.candidateId } }),
    }),
    createAnswerApiV1InsightAnswerQuestionIdPost: build.mutation<
      CreateAnswerApiV1InsightAnswerQuestionIdPostApiResponse,
      CreateAnswerApiV1InsightAnswerQuestionIdPostApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/insight/answer/${queryArg.questionId}`, method: 'POST', body: queryArg.answerCreate }),
    }),
    getAnswersForCampaignApiV1InsightAnswerCampaignCampaignIdGet: build.query<
      GetAnswersForCampaignApiV1InsightAnswerCampaignCampaignIdGetApiResponse,
      GetAnswersForCampaignApiV1InsightAnswerCampaignCampaignIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/insight/answer/campaign/${queryArg.campaignId}` }),
    }),
    deleteAnswerApiV1InsightAnswerAnswerIdDelete: build.mutation<
      DeleteAnswerApiV1InsightAnswerAnswerIdDeleteApiResponse,
      DeleteAnswerApiV1InsightAnswerAnswerIdDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/insight/answer/${queryArg.answerId}`, method: 'DELETE' }),
    }),
    updateAnswerApiV1InsightAnswerAnswerIdPatch: build.mutation<
      UpdateAnswerApiV1InsightAnswerAnswerIdPatchApiResponse,
      UpdateAnswerApiV1InsightAnswerAnswerIdPatchApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/insight/answer/${queryArg.answerId}`, method: 'PATCH', body: queryArg.answerUpdate }),
    }),
    updateFileApiV1InsightFileFileIdPatch: build.mutation<UpdateFileApiV1InsightFileFileIdPatchApiResponse, UpdateFileApiV1InsightFileFileIdPatchApiArg>({
      query: (queryArg) => ({ url: `/api/v1/insight/file/${queryArg.fileId}`, method: 'PATCH', body: queryArg.campaignFileUpdate }),
    }),
    possibleCandidatesApiV1InsightPossibleCandidatesCampaignIdGet: build.query<
      PossibleCandidatesApiV1InsightPossibleCandidatesCampaignIdGetApiResponse,
      PossibleCandidatesApiV1InsightPossibleCandidatesCampaignIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/insight/possible_candidates/${queryArg.campaignId}` }),
    }),
    parseFileApiV1InsightParseFilePost: build.mutation<ParseFileApiV1InsightParseFilePostApiResponse, ParseFileApiV1InsightParseFilePostApiArg>({
      query: (queryArg) => ({ url: `/api/v1/insight/parse_file`, method: 'POST', body: queryArg.bodyParseFileApiV1InsightParseFilePost }),
    }),
    persistFileApiV1InsightPersistFilePost: build.mutation<PersistFileApiV1InsightPersistFilePostApiResponse, PersistFileApiV1InsightPersistFilePostApiArg>({
      query: (queryArg) => ({ url: `/api/v1/insight/persist_file`, method: 'POST', body: queryArg.campaignFilePersist }),
    }),
    getFileApiV1InsightFileIdGet: build.query<GetFileApiV1InsightFileIdGetApiResponse, GetFileApiV1InsightFileIdGetApiArg>({
      query: (queryArg) => ({ url: `/api/v1/insight/file/${queryArg.id}`, params: { columns: queryArg.columns } }),
    }),
    deleteFileApiV1InsightFileIdDelete: build.mutation<DeleteFileApiV1InsightFileIdDeleteApiResponse, DeleteFileApiV1InsightFileIdDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/v1/insight/file/${queryArg.id}`, method: 'DELETE' }),
    }),
    getCandidateViewApiV1InsightCandidateViewIdGet: build.query<
      GetCandidateViewApiV1InsightCandidateViewIdGetApiResponse,
      GetCandidateViewApiV1InsightCandidateViewIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/insight/candidate_view/${queryArg.id}` }),
    }),
    getLandscapesListApiLandscapesGet: build.query<GetLandscapesListApiLandscapesGetApiResponse, GetLandscapesListApiLandscapesGetApiArg>({
      query: () => ({ url: `/api/landscapes/` }),
    }),
    getLandscapeApiLandscapesNameGet: build.query<GetLandscapeApiLandscapesNameGetApiResponse, GetLandscapeApiLandscapesNameGetApiArg>({
      query: (queryArg) => ({ url: `/api/landscapes/${queryArg.name}` }),
    }),
    updateLandscapeApiLandscapesNamePut: build.mutation<UpdateLandscapeApiLandscapesNamePutApiResponse, UpdateLandscapeApiLandscapesNamePutApiArg>({
      query: (queryArg) => ({ url: `/api/landscapes/${queryArg.name}`, method: 'PUT', body: queryArg.landscapeConfig }),
    }),
    createLandscapeApiLandscapesNamePost: build.mutation<CreateLandscapeApiLandscapesNamePostApiResponse, CreateLandscapeApiLandscapesNamePostApiArg>({
      query: (queryArg) => ({ url: `/api/landscapes/${queryArg.name}`, method: 'POST', body: queryArg.landscapeConfig }),
    }),
    deleteLandscapeApiLandscapesNameDelete: build.mutation<DeleteLandscapeApiLandscapesNameDeleteApiResponse, DeleteLandscapeApiLandscapesNameDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/landscapes/${queryArg.name}`, method: 'DELETE' }),
    }),
    validateLandscapeApiLandscapesNameValidatePost: build.mutation<
      ValidateLandscapeApiLandscapesNameValidatePostApiResponse,
      ValidateLandscapeApiLandscapesNameValidatePostApiArg
    >({
      query: (queryArg) => ({ url: `/api/landscapes/${queryArg.name}/validate`, method: 'POST', body: queryArg.landscapeConfig }),
    }),
    getMatomoConfigApiMatomoGet: build.query<GetMatomoConfigApiMatomoGetApiResponse, GetMatomoConfigApiMatomoGetApiArg>({
      query: () => ({ url: `/api/matomo/` }),
    }),
    getUsersListApiAdminUsersGet: build.query<GetUsersListApiAdminUsersGetApiResponse, GetUsersListApiAdminUsersGetApiArg>({
      query: () => ({ url: `/api/admin/users` }),
    }),
    searchUsersPaginatedApiAdminUsersSearchPost: build.query<
      SearchUsersPaginatedApiAdminUsersSearchPostApiResponse,
      SearchUsersPaginatedApiAdminUsersSearchPostApiArg
    >({
      query: (queryArg) => ({ url: `/api/admin/users/search`, method: 'POST', body: queryArg.extendedSearchArgs }),
    }),
    getAnalysisSessionsListApiAnalysisSessionsListGet: build.query<
      GetAnalysisSessionsListApiAnalysisSessionsListGetApiResponse,
      GetAnalysisSessionsListApiAnalysisSessionsListGetApiArg
    >({
      query: () => ({ url: `/api/analysis-sessions/list` }),
    }),
    getAnalysisSessionApiAnalysisSessionsIdGet: build.query<
      GetAnalysisSessionApiAnalysisSessionsIdGetApiResponse,
      GetAnalysisSessionApiAnalysisSessionsIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/analysis-sessions/${queryArg.id}` }),
    }),
    updateAnalysisSessionApiAnalysisSessionsIdPut: build.mutation<
      UpdateAnalysisSessionApiAnalysisSessionsIdPutApiResponse,
      UpdateAnalysisSessionApiAnalysisSessionsIdPutApiArg
    >({
      query: (queryArg) => ({ url: `/api/analysis-sessions/${queryArg.id}`, method: 'PUT', body: queryArg.analysisSessionUpdate }),
    }),
    deleteAnalysisSessionApiAnalysisSessionsIdDelete: build.mutation<
      DeleteAnalysisSessionApiAnalysisSessionsIdDeleteApiResponse,
      DeleteAnalysisSessionApiAnalysisSessionsIdDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/api/analysis-sessions/${queryArg.id}`, method: 'DELETE' }),
    }),
    createAnalysisSessionApiAnalysisSessionsCreatePost: build.mutation<
      CreateAnalysisSessionApiAnalysisSessionsCreatePostApiResponse,
      CreateAnalysisSessionApiAnalysisSessionsCreatePostApiArg
    >({
      query: (queryArg) => ({ url: `/api/analysis-sessions/create`, method: 'POST', body: queryArg.analysisSessionCreate }),
    }),
    checkSessionAvailabilityApiAnalysisSessionsCheckSessionAvailabilitySessionNameGet: build.query<
      CheckSessionAvailabilityApiAnalysisSessionsCheckSessionAvailabilitySessionNameGetApiResponse,
      CheckSessionAvailabilityApiAnalysisSessionsCheckSessionAvailabilitySessionNameGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/analysis-sessions/check-session-availability/${queryArg.sessionName}` }),
    }),
    getUploadedEntityListApiUploadListGet: build.query<GetUploadedEntityListApiUploadListGetApiResponse, GetUploadedEntityListApiUploadListGetApiArg>({
      query: () => ({ url: `/api/upload/list` }),
    }),
    entityPutApiUploadUuidPut: build.mutation<EntityPutApiUploadUuidPutApiResponse, EntityPutApiUploadUuidPutApiArg>({
      query: (queryArg) => ({ url: `/api/upload/${queryArg.uuid}`, method: 'PUT', body: queryArg.uploadedEntityUpdate }),
    }),
    entityPostApiUploadUuidPost: build.mutation<EntityPostApiUploadUuidPostApiResponse, EntityPostApiUploadUuidPostApiArg>({
      query: (queryArg) => ({ url: `/api/upload/${queryArg.uuid}`, method: 'POST', body: queryArg.uploadedEntityCreate }),
    }),
    entityDeleteApiUploadUuidDelete: build.mutation<EntityDeleteApiUploadUuidDeleteApiResponse, EntityDeleteApiUploadUuidDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/upload/${queryArg.uuid}`, method: 'DELETE' }),
    }),
    getUploadApiFilesUuidGet: build.query<GetUploadApiFilesUuidGetApiResponse, GetUploadApiFilesUuidGetApiArg>({
      query: (queryArg) => ({ url: `/api/files/${queryArg.uuid}` }),
    }),
    deleteUploadApiFilesUuidDelete: build.mutation<DeleteUploadApiFilesUuidDeleteApiResponse, DeleteUploadApiFilesUuidDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/files/${queryArg.uuid}`, method: 'DELETE' }),
    }),
    optionsUploadChunkApiFilesUuidOptions: build.mutation<OptionsUploadChunkApiFilesUuidOptionsApiResponse, OptionsUploadChunkApiFilesUuidOptionsApiArg>({
      query: (queryArg) => ({ url: `/api/files/${queryArg.uuid}`, method: 'OPTIONS' }),
    }),
    getUploadMetadataApiFilesUuidHead: build.mutation<GetUploadMetadataApiFilesUuidHeadApiResponse, GetUploadMetadataApiFilesUuidHeadApiArg>({
      query: (queryArg) => ({ url: `/api/files/${queryArg.uuid}`, method: 'HEAD' }),
    }),
    uploadChunkApiFilesUuidPatch: build.mutation<UploadChunkApiFilesUuidPatchApiResponse, UploadChunkApiFilesUuidPatchApiArg>({
      query: (queryArg) => ({
        url: `/api/files/${queryArg.uuid}`,
        method: 'PATCH',
        headers: { 'content-type': queryArg['content-type'], 'content-length': queryArg['content-length'], 'upload-offset': queryArg['upload-offset'] },
        params: { post_request: queryArg.postRequest },
      }),
    }),
    createUploadApiFilesPost: build.mutation<CreateUploadApiFilesPostApiResponse, CreateUploadApiFilesPostApiArg>({
      query: (queryArg) => ({
        url: `/api/files/`,
        method: 'POST',
        headers: {
          'upload-metadata': queryArg['upload-metadata'],
          'upload-length': queryArg['upload-length'],
          'upload-defer-length': queryArg['upload-defer-length'],
        },
      }),
    }),
    optionsCreateUploadApiFilesOptions: build.mutation<OptionsCreateUploadApiFilesOptionsApiResponse, OptionsCreateUploadApiFilesOptionsApiArg>({
      query: () => ({ url: `/api/files/`, method: 'OPTIONS' }),
    }),
    getUploadedFilesListMetadataApiFilesListMetadataGet: build.query<
      GetUploadedFilesListMetadataApiFilesListMetadataGetApiResponse,
      GetUploadedFilesListMetadataApiFilesListMetadataGetApiArg
    >({
      query: () => ({ url: `/api/files/list/metadata/` }),
    }),
    drawSmilesApiRdkitGet: build.query<DrawSmilesApiRdkitGetApiResponse, DrawSmilesApiRdkitGetApiArg>({
      query: (queryArg) => ({
        url: `/api/rdkit/`,
        params: { structure: queryArg.structure, substructure: queryArg.substructure, align: queryArg.align, size: queryArg.size },
      }),
    }),
    multipleImagesApiRdkitPost: build.mutation<MultipleImagesApiRdkitPostApiResponse, MultipleImagesApiRdkitPostApiArg>({
      query: (queryArg) => ({ url: `/api/rdkit/`, method: 'POST', body: queryArg.structures, params: { size: queryArg.size } }),
    }),
    drawMurckoApiRdkitMurckoGet: build.query<DrawMurckoApiRdkitMurckoGetApiResponse, DrawMurckoApiRdkitMurckoGetApiArg>({
      query: (queryArg) => ({ url: `/api/rdkit/murcko/`, params: { structure: queryArg.structure, size: queryArg.size } }),
    }),
    drawMoleculeSimilarityApiRdkitSimilarityGet: build.query<
      DrawMoleculeSimilarityApiRdkitSimilarityGetApiResponse,
      DrawMoleculeSimilarityApiRdkitSimilarityGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/rdkit/similarity/`, params: { structure: queryArg.structure, reference: queryArg.reference } }),
    }),
    drawMaximumCommonSubstructureMoleculeApiRdkitMcsPost: build.mutation<
      DrawMaximumCommonSubstructureMoleculeApiRdkitMcsPostApiResponse,
      DrawMaximumCommonSubstructureMoleculeApiRdkitMcsPostApiArg
    >({
      query: (queryArg) => ({ url: `/api/rdkit/mcs/`, method: 'POST', body: queryArg.structures, params: { size: queryArg.size } }),
    }),
    substructuresCountApiRdkitSubstructuresPost: build.mutation<
      SubstructuresCountApiRdkitSubstructuresPostApiResponse,
      SubstructuresCountApiRdkitSubstructuresPostApiArg
    >({
      query: (queryArg) => ({ url: `/api/rdkit/substructures/`, method: 'POST', body: queryArg.structures, params: { substructure: queryArg.substructure } }),
    }),
    getConfigPathApiTdpConfigPathGet: build.query<GetConfigPathApiTdpConfigPathGetApiResponse, GetConfigPathApiTdpConfigPathGetApiArg>({
      query: (queryArg) => ({ url: `/api/tdp/config/${queryArg.path}` }),
    }),
    proxySentryEnvelopeApiSentryPost: build.mutation<ProxySentryEnvelopeApiSentryPostApiResponse, ProxySentryEnvelopeApiSentryPostApiArg>({
      query: () => ({ url: `/api/sentry/`, method: 'POST' }),
    }),
    getClientConfigApiV1VisynClientConfigGet: build.query<GetClientConfigApiV1VisynClientConfigGetApiResponse, GetClientConfigApiV1VisynClientConfigGetApiArg>({
      query: () => ({ url: `/api/v1/visyn/clientConfig` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as visynApi };
export type GetLoginApiLoginGetApiResponse = unknown;
export type GetLoginApiLoginGetApiArg = void;
export type PostLoginApiLoginPostApiResponse = /** status 200 Successful Response */ Token;
export type PostLoginApiLoginPostApiArg = {
  bodyPostLoginApiLoginPost: BodyPostLoginApiLoginPost;
};
export type LogoutApiLogoutPostApiResponse = /** status 200 Successful Response */ any;
export type LogoutApiLogoutPostApiArg = void;
export type LoggedinasApiLoggedinasGetApiResponse = /** status 200 Successful Response */ any;
export type LoggedinasApiLoggedinasGetApiArg = void;
export type LoggedinasApiLoggedinasPostApiResponse = /** status 200 Successful Response */ any;
export type LoggedinasApiLoggedinasPostApiArg = void;
export type StoresApiSecurityStoresGetApiResponse = /** status 200 Successful Response */ SecurityStoreResponse[];
export type StoresApiSecurityStoresGetApiArg = void;
export type ListIdtypesApiIdtypeGetApiResponse = /** status 200 Successful Response */ IdType[];
export type ListIdtypesApiIdtypeGetApiArg = void;
export type MapsToApiIdtypeIdtypeGetApiResponse = /** status 200 Successful Response */ IdType[];
export type MapsToApiIdtypeIdtypeGetApiArg = {
  idtype: string;
};
export type MappingToApiIdtypeIdtypeToIdtypeGetApiResponse = /** status 200 Successful Response */ string[];
export type MappingToApiIdtypeIdtypeToIdtypeGetApiArg = {
  idtype: string;
  toIdtype: string;
  idTypeMappingRequest: IdTypeMappingRequest;
};
export type MappingToApiIdtypeIdtypeToIdtypePostApiResponse = /** status 200 Successful Response */ string[];
export type MappingToApiIdtypeIdtypeToIdtypePostApiArg = {
  idtype: string;
  toIdtype: string;
  idTypeMappingRequest: IdTypeMappingRequest;
};
export type MappingToSearchApiIdtypeIdtypeToIdtypeSearchGetApiResponse = /** status 200 Successful Response */ IdTypeMappingSearchResponse[];
export type MappingToSearchApiIdtypeIdtypeToIdtypeSearchGetApiArg = {
  idtype: any;
  toIdtype: any;
  idTypeMappingSearchRequest: IdTypeMappingSearchRequest;
};
export type GetPluginsApiTdpPluginGetApiResponse = /** status 200 Successful Response */ any;
export type GetPluginsApiTdpPluginGetApiArg = void;
export type Xlsx2JsonApiXlsxToJsonPostApiResponse = /** status 200 Successful Response */ TableData;
export type Xlsx2JsonApiXlsxToJsonPostApiArg = {
  bodyXlsx2JsonApiXlsxToJsonPost: BodyXlsx2JsonApiXlsxToJsonPost;
};
export type Xlsx2JsonArrayApiXlsxToJsonArrayPostApiResponse = /** status 200 Successful Response */ any[][];
export type Xlsx2JsonArrayApiXlsxToJsonArrayPostApiArg = {
  bodyXlsx2JsonArrayApiXlsxToJsonArrayPost: BodyXlsx2JsonArrayApiXlsxToJsonArrayPost;
};
export type Json2XlsxApiXlsxFromJsonPostApiResponse = /** status 200 Successful Response */ any;
export type Json2XlsxApiXlsxFromJsonPostApiArg = {
  tableData: TableData;
};
export type JsonArray2XlsxApiXlsxFromJsonArrayPostApiResponse = /** status 200 Successful Response */ any;
export type JsonArray2XlsxApiXlsxFromJsonArrayPostApiArg = {
  data: any[][];
};
export type BuildInfoBuildInfoJsonGetApiResponse = /** status 200 Successful Response */ any;
export type BuildInfoBuildInfoJsonGetApiArg = void;
export type HealthApiHealthGetApiResponse = /** status 200 Successful Response */ any;
export type HealthApiHealthGetApiArg = void;
export type HealthApiHealthHeadApiResponse = /** status 200 Successful Response */ any;
export type HealthApiHealthHeadApiArg = void;
export type DeprecatedHealthHealthGetApiResponse = /** status 200 Successful Response */ any;
export type DeprecatedHealthHealthGetApiArg = void;
export type DeprecatedHealthHealthHeadApiResponse = /** status 200 Successful Response */ any;
export type DeprecatedHealthHealthHeadApiArg = void;
export type SubscribeApiV1InsightSubscribeGetApiResponse =
  /** status 200 Successful Response */
  | ConnectedSseMessage
  | CreateAnswerSseMessage
  | UpdateAnswerSseMessage
  | DeleteAnswerSseMessage
  | CreateCampaignSseMessage
  | UpdateCampaignSseMessage
  | DeleteCampaignSseMessage;
export type SubscribeApiV1InsightSubscribeGetApiArg = void;
export type GetCampaignsApiV1InsightCampaignsGetApiResponse = /** status 200 Successful Response */ CampaignRead[];
export type GetCampaignsApiV1InsightCampaignsGetApiArg = void;
export type GetCampaignApiV1InsightCampaignIdGetApiResponse = /** status 200 Successful Response */ CampaignRead;
export type GetCampaignApiV1InsightCampaignIdGetApiArg = {
  id: string;
};
export type DeleteCampaignApiV1InsightCampaignIdDeleteApiResponse = /** status 200 Successful Response */ boolean;
export type DeleteCampaignApiV1InsightCampaignIdDeleteApiArg = {
  id: string;
};
export type UpdateCampaignApiV1InsightCampaignIdPatchApiResponse = /** status 200 Successful Response */ CampaignRead;
export type UpdateCampaignApiV1InsightCampaignIdPatchApiArg = {
  id: string;
  campaignUpdate: CampaignUpdate;
};
export type CreateCampaignApiV1InsightCampaignPostApiResponse = /** status 200 Successful Response */ CampaignRead;
export type CreateCampaignApiV1InsightCampaignPostApiArg = {
  campaignCreate: CampaignCreate;
};
export type AddCandidatesApiV1InsightCampaignIdAddCandidatesPostApiResponse = /** status 200 Successful Response */ CampaignRead;
export type AddCandidatesApiV1InsightCampaignIdAddCandidatesPostApiArg = {
  id: any;
  candidates: CandidateCreate[];
};
export type GetAnswersApiV1InsightAnswerQuestionIdGetApiResponse = /** status 200 Successful Response */ AnswerRead[];
export type GetAnswersApiV1InsightAnswerQuestionIdGetApiArg = {
  questionId: number;
  candidateId?: string;
};
export type CreateAnswerApiV1InsightAnswerQuestionIdPostApiResponse = /** status 200 Successful Response */ Answer;
export type CreateAnswerApiV1InsightAnswerQuestionIdPostApiArg = {
  questionId: number;
  answerCreate: AnswerCreate;
};
export type GetAnswersForCampaignApiV1InsightAnswerCampaignCampaignIdGetApiResponse = /** status 200 Successful Response */ {
  [key: string]: AnswerRead[];
};
export type GetAnswersForCampaignApiV1InsightAnswerCampaignCampaignIdGetApiArg = {
  campaignId: string;
};
export type DeleteAnswerApiV1InsightAnswerAnswerIdDeleteApiResponse = /** status 200 Successful Response */ AnswerDeleteRead;
export type DeleteAnswerApiV1InsightAnswerAnswerIdDeleteApiArg = {
  answerId: number;
};
export type UpdateAnswerApiV1InsightAnswerAnswerIdPatchApiResponse = /** status 200 Successful Response */ Answer;
export type UpdateAnswerApiV1InsightAnswerAnswerIdPatchApiArg = {
  answerId: number;
  answerUpdate: AnswerUpdate;
};
export type UpdateFileApiV1InsightFileFileIdPatchApiResponse = /** status 200 Successful Response */ CampaignFile;
export type UpdateFileApiV1InsightFileFileIdPatchApiArg = {
  fileId: string;
  campaignFileUpdate: CampaignFileUpdate;
};
export type PossibleCandidatesApiV1InsightPossibleCandidatesCampaignIdGetApiResponse = /** status 200 Successful Response */ string[];
export type PossibleCandidatesApiV1InsightPossibleCandidatesCampaignIdGetApiArg = {
  campaignId: string;
};
export type ParseFileApiV1InsightParseFilePostApiResponse = /** status 200 Successful Response */ CampaignFileParseResult;
export type ParseFileApiV1InsightParseFilePostApiArg = {
  bodyParseFileApiV1InsightParseFilePost: BodyParseFileApiV1InsightParseFilePost;
};
export type PersistFileApiV1InsightPersistFilePostApiResponse = /** status 200 Successful Response */ CampaignFileRead;
export type PersistFileApiV1InsightPersistFilePostApiArg = {
  campaignFilePersist: CampaignFilePersist;
};
export type GetFileApiV1InsightFileIdGetApiResponse = /** status 200 Successful Response */ {
  [key: string]: any[];
};
export type GetFileApiV1InsightFileIdGetApiArg = {
  id: string;
  columns?: string[];
};
export type DeleteFileApiV1InsightFileIdDeleteApiResponse = /** status 200 Successful Response */ boolean;
export type DeleteFileApiV1InsightFileIdDeleteApiArg = {
  id: string;
};
export type GetCandidateViewApiV1InsightCandidateViewIdGetApiResponse = /** status 200 Successful Response */ CandidateViewRead;
export type GetCandidateViewApiV1InsightCandidateViewIdGetApiArg = {
  id: string;
};
export type GetLandscapesListApiLandscapesGetApiResponse = /** status 200 Successful Response */ LandscapeDescription[];
export type GetLandscapesListApiLandscapesGetApiArg = void;
export type GetLandscapeApiLandscapesNameGetApiResponse = /** status 200 Successful Response */ LandscapeDescription;
export type GetLandscapeApiLandscapesNameGetApiArg = {
  name: string;
};
export type UpdateLandscapeApiLandscapesNamePutApiResponse = /** status 200 Successful Response */ LandscapeDescription;
export type UpdateLandscapeApiLandscapesNamePutApiArg = {
  name: string;
  landscapeConfig: object;
};
export type CreateLandscapeApiLandscapesNamePostApiResponse = /** status 200 Successful Response */ LandscapeDescription;
export type CreateLandscapeApiLandscapesNamePostApiArg = {
  name: string;
  landscapeConfig: object;
};
export type DeleteLandscapeApiLandscapesNameDeleteApiResponse = /** status 200 Successful Response */ LandscapeDescription;
export type DeleteLandscapeApiLandscapesNameDeleteApiArg = {
  name: string;
};
export type ValidateLandscapeApiLandscapesNameValidatePostApiResponse = /** status 200 Successful Response */ string;
export type ValidateLandscapeApiLandscapesNameValidatePostApiArg = {
  name: string;
  landscapeConfig: object;
};
export type GetMatomoConfigApiMatomoGetApiResponse = /** status 200 Successful Response */ MatomoConfig;
export type GetMatomoConfigApiMatomoGetApiArg = void;
export type GetUsersListApiAdminUsersGetApiResponse = /** status 200 Successful Response */ User[];
export type GetUsersListApiAdminUsersGetApiArg = void;
export type SearchUsersPaginatedApiAdminUsersSearchPostApiResponse = /** status 200 Successful Response */ User[];
export type SearchUsersPaginatedApiAdminUsersSearchPostApiArg = {
  extendedSearchArgs: ExtendedSearchArgs;
};
export type GetAnalysisSessionsListApiAnalysisSessionsListGetApiResponse = /** status 200 Successful Response */ AnalysisSession[];
export type GetAnalysisSessionsListApiAnalysisSessionsListGetApiArg = void;
export type GetAnalysisSessionApiAnalysisSessionsIdGetApiResponse = /** status 200 Successful Response */ AnalysisSession;
export type GetAnalysisSessionApiAnalysisSessionsIdGetApiArg = {
  id: string;
};
export type UpdateAnalysisSessionApiAnalysisSessionsIdPutApiResponse = /** status 200 Successful Response */ AnalysisSession;
export type UpdateAnalysisSessionApiAnalysisSessionsIdPutApiArg = {
  id: string;
  analysisSessionUpdate: AnalysisSessionUpdate;
};
export type DeleteAnalysisSessionApiAnalysisSessionsIdDeleteApiResponse = /** status 200 Successful Response */ string;
export type DeleteAnalysisSessionApiAnalysisSessionsIdDeleteApiArg = {
  id: string;
};
export type CreateAnalysisSessionApiAnalysisSessionsCreatePostApiResponse = /** status 200 Successful Response */ AnalysisSession;
export type CreateAnalysisSessionApiAnalysisSessionsCreatePostApiArg = {
  analysisSessionCreate: AnalysisSessionCreate;
};
export type CheckSessionAvailabilityApiAnalysisSessionsCheckSessionAvailabilitySessionNameGetApiResponse = /** status 200 Successful Response */ boolean;
export type CheckSessionAvailabilityApiAnalysisSessionsCheckSessionAvailabilitySessionNameGetApiArg = {
  sessionName: string;
};
export type GetUploadedEntityListApiUploadListGetApiResponse = /** status 200 Successful Response */ UploadedEntityMetaResponse[];
export type GetUploadedEntityListApiUploadListGetApiArg = void;
export type EntityPutApiUploadUuidPutApiResponse = /** status 200 Successful Response */ UploadedEntityMetaResponse;
export type EntityPutApiUploadUuidPutApiArg = {
  uuid: string;
  uploadedEntityUpdate: UploadedEntityUpdate;
};
export type EntityPostApiUploadUuidPostApiResponse = /** status 200 Successful Response */ UploadedEntityMetaResponse;
export type EntityPostApiUploadUuidPostApiArg = {
  uuid: string;
  uploadedEntityCreate: UploadedEntityCreate;
};
export type EntityDeleteApiUploadUuidDeleteApiResponse = /** status 200 Successful Response */ UploadedEntityMetaResponse;
export type EntityDeleteApiUploadUuidDeleteApiArg = {
  uuid: string;
};
export type GetUploadApiFilesUuidGetApiResponse = /** status 200 Successful Response */ any;
export type GetUploadApiFilesUuidGetApiArg = {
  uuid: string;
};
export type DeleteUploadApiFilesUuidDeleteApiResponse = /** status 204 Successful Response */ void;
export type DeleteUploadApiFilesUuidDeleteApiArg = {
  uuid: string;
};
export type OptionsUploadChunkApiFilesUuidOptionsApiResponse = /** status 204 Successful Response */ void;
export type OptionsUploadChunkApiFilesUuidOptionsApiArg = {
  uuid: string;
};
export type GetUploadMetadataApiFilesUuidHeadApiResponse = /** status 200 Successful Response */ any;
export type GetUploadMetadataApiFilesUuidHeadApiArg = {
  uuid: string;
};
export type UploadChunkApiFilesUuidPatchApiResponse = /** status 204 Successful Response */ void;
export type UploadChunkApiFilesUuidPatchApiArg = {
  uuid: string;
  postRequest?: boolean;
  'content-type'?: string;
  'content-length'?: number;
  'upload-offset'?: number;
};
export type CreateUploadApiFilesPostApiResponse = /** status 201 Successful Response */ any;
export type CreateUploadApiFilesPostApiArg = {
  'upload-metadata'?: string;
  'upload-length'?: number;
  'upload-defer-length'?: number;
};
export type OptionsCreateUploadApiFilesOptionsApiResponse = unknown;
export type OptionsCreateUploadApiFilesOptionsApiArg = void;
export type GetUploadedFilesListMetadataApiFilesListMetadataGetApiResponse = /** status 200 Successful Response */ EntityUploadMetadata[];
export type GetUploadedFilesListMetadataApiFilesListMetadataGetApiArg = void;
export type DrawSmilesApiRdkitGetApiResponse = /** status 200 Successful Response */ string;
export type DrawSmilesApiRdkitGetApiArg = {
  structure: string;
  substructure?: string;
  align?: string;
  size?: number;
};
export type MultipleImagesApiRdkitPostApiResponse = /** status 200 Successful Response */ any;
export type MultipleImagesApiRdkitPostApiArg = {
  size?: number;
  structures: string[];
};
export type DrawMurckoApiRdkitMurckoGetApiResponse = /** status 200 Successful Response */ string;
export type DrawMurckoApiRdkitMurckoGetApiArg = {
  structure: string;
  size?: number;
};
export type DrawMoleculeSimilarityApiRdkitSimilarityGetApiResponse = /** status 200 Successful Response */ string;
export type DrawMoleculeSimilarityApiRdkitSimilarityGetApiArg = {
  structure: string;
  reference: string;
};
export type DrawMaximumCommonSubstructureMoleculeApiRdkitMcsPostApiResponse = /** status 200 Successful Response */ string;
export type DrawMaximumCommonSubstructureMoleculeApiRdkitMcsPostApiArg = {
  size?: number;
  structures: string[];
};
export type SubstructuresCountApiRdkitSubstructuresPostApiResponse = /** status 200 Successful Response */ SubstructuresResponse;
export type SubstructuresCountApiRdkitSubstructuresPostApiArg = {
  substructure: string;
  structures: string[];
};
export type GetConfigPathApiTdpConfigPathGetApiResponse = /** status 200 Successful Response */ any;
export type GetConfigPathApiTdpConfigPathGetApiArg = {
  path: string;
};
export type ProxySentryEnvelopeApiSentryPostApiResponse = /** status 200 Successful Response */ any;
export type ProxySentryEnvelopeApiSentryPostApiArg = void;
export type GetClientConfigApiV1VisynClientConfigGetApiResponse = /** status 200 Successful Response */ AppConfigModel;
export type GetClientConfigApiV1VisynClientConfigGetApiArg = void;
export type Token = {
  access_token: string;
  token_type: string;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type BodyPostLoginApiLoginPost = {
  grant_type?: string;
  username: string;
  password: string;
  scope?: string;
  client_id?: string;
  client_secret?: string;
};
export type SecurityStoreResponse = {
  id: string;
  ui?: string;
  configuration?: object;
};
export type IdType = {
  id: string;
  name: string;
  names: string[];
};
export type IdTypeMappingRequest = {
  q: string[];
  mode?: 'all' | 'first';
};
export type IdTypeMappingSearchResponse = {
  match: string;
  to: string;
};
export type IdTypeMappingSearchRequest = {
  q: string;
  limit?: number;
};
export type TableColumn = {
  name: string;
  type: string;
};
export type TableSheet = {
  title: string;
  columns: TableColumn[];
  rows: object[];
};
export type TableData = {
  sheets: TableSheet[];
};
export type BodyXlsx2JsonApiXlsxToJsonPost = {
  file: Blob;
};
export type BodyXlsx2JsonArrayApiXlsxToJsonArrayPost = {
  file: Blob;
};
export type ConnectedSseMessage = {
  type?: 'connected';
  value: string;
};
export type AnswerDeleteRead = {
  id: number;
  question_id: number;
};
export type CreateAnswerSseMessage = {
  type?: 'create_answer';
  value: AnswerDeleteRead;
};
export type UpdateAnswerSseMessage = {
  type?: 'update_answer';
  value: AnswerDeleteRead;
};
export type DeleteAnswerSseMessage = {
  type?: 'delete_answer';
  value: AnswerDeleteRead;
};
export type CreateCampaignSseMessage = {
  type?: 'create_campaign';
  value: string;
};
export type UpdateCampaignSseMessage = {
  type?: 'update_campaign';
  value: string;
};
export type DeleteCampaignSseMessage = {
  type?: 'delete_campaign';
  value: string;
};
export type ReasonForInclusion = {
  text: string;
  user_id: string;
  creation_date: string;
};
export type CandidateBase = {
  id: string;
  name: string;
  type: string;
  reasons_for_inclusion: ReasonForInclusion[];
};
export type CampaignExtras = {
  contextCandidates?: CandidateBase[];
};
export type CampaignMemberRead = {
  id: string;
  role: 'viewer' | 'member' | 'owner';
};
export type AppQuestionLinkMeta = {
  app_id: string;
  url_params?: object;
};
export type AppQuestionLink = {
  type?: 'app';
  meta: AppQuestionLinkMeta;
};
export type FileQuestionLinkMeta = {
  file: string;
  column: string;
};
export type FileQuestionLink = {
  type?: 'file';
  meta: FileQuestionLinkMeta;
};
export type ScoreQuestionLinkMeta = {
  id: string;
  params?: object | object[];
};
export type ScoreQuestionLink = {
  type?: 'score';
  meta: ScoreQuestionLinkMeta;
};
export type HtmlQuestionLinkMeta = {
  html_link: string;
};
export type HtmlQuestionLink = {
  type?: 'html';
  meta: HtmlQuestionLinkMeta;
};
export type QuestionRead = {
  text: string;
  type: string;
  links?: (
    | ({
        type: 'app';
      } & AppQuestionLink)
    | ({
        type: 'file';
      } & FileQuestionLink)
    | ({
        type: 'score';
      } & ScoreQuestionLink)
    | ({
        type: 'html';
      } & HtmlQuestionLink)
  )[];
  id: number;
};
export type PdfFileReadMeta = {
  mime_type?: 'application/pdf';
};
export type JsonFileReadMeta = {
  mime_type?: 'application/json';
  columns: string[];
  candidate_column: string;
  dump?: object;
};
export type CampaignFileRead = {
  id: string;
  name: string;
  creator: string;
  creation_date: string;
  meta:
    | ({
        mime_type: 'application/pdf';
      } & PdfFileReadMeta)
    | ({
        mime_type: 'application/json';
      } & JsonFileReadMeta);
};
export type CandidateRead = {
  id: string;
  name: string;
  type: string;
  reasons_for_inclusion: ReasonForInclusion[];
  creation_date: string;
  modification_date?: string;
};
export type CandidateViewMeta = {
  dump?: object;
};
export type CandidateViewRead = {
  name: string;
  meta: CandidateViewMeta;
  id: string;
  creator: string;
  creation_date: string;
};
export type CampaignRead = {
  name: string;
  extras: CampaignExtras;
  description?: string;
  access_type: 'private' | 'public';
  visitor_type: 'viewer' | 'member' | 'owner';
  id: string;
  type: string;
  creation_date: string;
  modification_date?: string;
  archived: boolean;
  tags: string[];
  members: CampaignMemberRead[];
  questions: QuestionRead[];
  files: CampaignFileRead[];
  candidates: CandidateRead[];
  candidate_views: CandidateViewRead[];
};
export type ReasonForInclusionCreateUpdate = {
  text: string;
  user_id?: string;
  creation_date?: string;
};
export type CandidateUpdate = {
  id: string;
  name: string;
  type: string;
  reasons_for_inclusion: ReasonForInclusionCreateUpdate[];
};
export type CampaignExtrasUpdate = {
  contextCandidates?: CandidateUpdate[];
};
export type CampaignMemberUpdate = {
  id: string;
  role: 'viewer' | 'member' | 'owner';
};
export type QuestionUpdate = {
  text: string;
  type: string;
  links?: (
    | ({
        type: 'app';
      } & AppQuestionLink)
    | ({
        type: 'file';
      } & FileQuestionLink)
    | ({
        type: 'score';
      } & ScoreQuestionLink)
    | ({
        type: 'html';
      } & HtmlQuestionLink)
  )[];
  id?: number;
};
export type CampaignFileUpdate = {
  id: string;
  name?: string;
  meta?: PdfFileReadMeta | JsonFileReadMeta;
};
export type CandidateViewUpdate = {
  name: string;
  meta: CandidateViewMeta;
  id?: string;
};
export type CampaignUpdate = {
  name?: string;
  extras?: CampaignExtrasUpdate;
  description?: string;
  access_type?: 'private' | 'public';
  visitor_type?: 'viewer' | 'member' | 'owner';
  tags?: string[];
  members?: CampaignMemberUpdate[];
  questions?: QuestionUpdate[];
  files?: CampaignFileUpdate[];
  candidates?: CandidateUpdate[];
  candidate_views?: CandidateViewUpdate[];
  archived?: boolean;
};
export type CandidateCreate = {
  id: string;
  name: string;
  type: string;
  reasons_for_inclusion: ReasonForInclusionCreateUpdate[];
};
export type CampaignExtrasCreate = {
  contextCandidates?: CandidateCreate[];
};
export type CampaignMemberCreate = {
  id: string;
  role: 'viewer' | 'member' | 'owner';
};
export type QuestionCreate = {
  text: string;
  type: string;
  links?: (
    | ({
        type: 'app';
      } & AppQuestionLink)
    | ({
        type: 'file';
      } & FileQuestionLink)
    | ({
        type: 'score';
      } & ScoreQuestionLink)
    | ({
        type: 'html';
      } & HtmlQuestionLink)
  )[];
};
export type CampaignFileCreate = {
  file_id: string;
  meta:
    | ({
        mime_type: 'application/pdf';
      } & PdfFileReadMeta)
    | ({
        mime_type: 'application/json';
      } & JsonFileReadMeta);
};
export type CandidateViewCreate = {
  name: string;
  meta: CandidateViewMeta;
};
export type CampaignCreate = {
  name: string;
  extras?: CampaignExtrasCreate;
  description?: string;
  access_type: 'private' | 'public';
  visitor_type: 'viewer' | 'member' | 'owner';
  type: string;
  tags: string[];
  members: CampaignMemberCreate[];
  questions: QuestionCreate[];
  files: CampaignFileCreate[];
  candidates: CandidateCreate[];
  candidate_views?: CandidateViewCreate[];
};
export type FreetextAnswer = {
  type?: 'freetext';
  value?: object;
};
export type RatingAnswer = {
  type?: 'rating';
  value: number;
};
export type AnswerRead = {
  text: string;
  id: number;
  payload:
    | ({
        type: 'freetext';
      } & FreetextAnswer)
    | ({
        type: 'rating';
      } & RatingAnswer);
  user_id: string;
  candidate: CandidateRead;
  creation_date: string;
  modification_date?: string;
};
export type Answer = {
  text: string;
  id?: number;
  payload?: object;
  user_id: string;
  creation_date?: string;
  modification_date?: string;
  question_id: number;
  campaign_id: string;
  candidate_id: string;
};
export type AnswerCreate = {
  text: string;
  candidate_id: string;
  candidate: CandidateCreate;
  payload:
    | ({
        type: 'freetext';
      } & FreetextAnswer)
    | ({
        type: 'rating';
      } & RatingAnswer);
};
export type AnswerUpdate = {
  text?: string;
  payload?: FreetextAnswer | RatingAnswer;
};
export type CampaignFile = {
  id?: string;
  name: string;
  creator: string;
  creation_date?: string;
  /** deferred */
  value: {
    [key: string]: any[];
  };
  meta?: object;
  campaign_id?: string;
};
export type JsonFileUploadMeta = {
  mime_type?: 'application/json';
  columns: string[];
  dump?: object;
};
export type CampaignFileParseResult = {
  name: string;
  meta: JsonFileUploadMeta;
  file_content: {
    [key: string]: any[];
  };
};
export type BodyParseFileApiV1InsightParseFilePost = {
  file: Blob;
};
export type CampaignFilePersist = {
  name: string;
  file_content: {
    [key: string]: any[];
  };
  meta: JsonFileReadMeta;
  campaign_id?: string;
};
export type User = {
  id: number;
  active: boolean;
  firstName?: string;
  lastName?: string;
  username?: string;
  email: string;
};
export type LandscapeDescription = {
  name: string;
  type: 'file' | 'db' | 'upload';
  json_obj: object;
  createdBy?: User;
  changedBy?: User;
  created_on?: string;
  changed_on?: string;
};
export type MatomoConfig = {
  /** Enable Matomo tracking. If enabled, Matomo tracking will be enabled in the Ordino application and send tracking requests on user action. The URL and site ID must be configured to enable tracking. */
  enabled?: boolean;
  /** Matomo tracking URL. This URL is used to send tracking data to the Matomo server. */
  url?: string;
  /** Matomo site ID. This ID is used to identify the Ordino application in the Matomo server. */
  site_id?: number;
  /** Enable hashing of the user ID. If enabled, the user ID will be hashed before sending it to the Matomo server. Otherwise the clear user ID will be sent. */
  hash_user_id?: boolean;
};
export type Category = {
  name: string;
  label?: string;
  color?: string;
  value?: number;
  icon?: string;
};
export type GlobalQuery = {
  name: string;
  availableCategories?: Category[];
  defaultCategories?: Category[];
  appliedCategories?: Category[];
};
export type SparseColumn = {
  categoryValue: string;
  entityId: string;
  categoryKey: string;
};
export type ExtendedSearchArgs = {
  globalQuery?: GlobalQuery;
  namedIdSetId?: number;
  selectColumns?: string[];
  whereColumns?: string[];
  searchString: string | string[] | number[];
  fullMatch: boolean;
  page?: number;
  pageSize?: number;
  sparseColumn?: SparseColumn;
};
export type AnalysisSnapshot = {
  id: string;
  name: string;
  description?: string;
  snapshot: object;
  sessionId: string;
  appVersion: string;
  createdOn: string;
  createdBy: string;
  changedOn?: string;
  changedBy?: string;
};
export type AnalysisSession = {
  id: string;
  name: string;
  description?: string;
  currentSnapshot: string;
  selectedSnapshot?: string;
  public: boolean;
  collabs: string[];
  appName: string;
  createdOn: string;
  createdBy: string;
  changedOn?: string;
  changedBy?: string;
  isSharedSession?: boolean;
  snapshots?: AnalysisSnapshot[];
};
export type AnalysisSnapshotCreate = {
  id: string;
  name: string;
  description?: string;
  snapshot: object;
  sessionId: string;
  createdOn: string;
  createdBy: string;
  changedOn?: string;
  changedBy?: string;
};
export type AnalysisSessionUpdate = {
  name?: string;
  description?: string;
  currentSnapshot?: string;
  selectedSnapshot?: string;
  public?: boolean;
  collabs?: string[];
  appName?: string;
  snapshots?: AnalysisSnapshotCreate[];
};
export type AnalysisSessionCreate = {
  id: string;
  name: string;
  description?: string;
  currentSnapshot: string;
  selectedSnapshot?: string;
  public: boolean;
  collabs?: string[];
  appName: string;
  snapshots: AnalysisSnapshotCreate[];
};
export type BooleanColumn = {
  /** The name of the column in the database. */
  columnName: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** Multi-line description of the column. */
  description?: string;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** Default cell renderer to use */
  renderer?: 'boolean' | 'default' | ('categorical' | 'set' | 'table' | 'cattick');
  /** Default group renderer to use */
  groupRenderer?: 'default' | ('catdistributionbar' | 'categorical' | 'set' | 'table');
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?: 'default' | ('catdistributionbar' | 'categorical' | 'set' | 'table');
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Initial width of the column. */
  width?: number;
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  type?: 'boolean' | 'BOOLEAN';
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** String to show for `true` values. */
  trueMarker?: string;
  /** String to show for `false` values. */
  falseMarker?: string;
  /** List of values that indicate `true` values (case insensitive). */
  trueValues?: string[];
};
export type CategoricalColumn = {
  /** The name of the column in the database. */
  columnName: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** Multi-line description of the column. */
  description?: string;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** Default cell renderer to use */
  renderer?: 'default' | ('categorical' | 'set' | 'table' | 'cattick');
  /** Default group renderer to use */
  groupRenderer?: 'default' | ('catdistributionbar' | 'categorical' | 'set' | 'table');
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?: 'default' | ('catdistributionbar' | 'categorical' | 'set' | 'table');
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Initial width of the column. */
  width?: number;
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  type?: 'categorical';
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** List of categories, either as strings or as Category objects */
  categories?: (Category | string)[];
  /** Possible values are:
    - `given` no sorting applied
    - `small-to-large` sort categories by number of entries per category from small to large
    - `large-to-small` sort categories by number of entries per category from large to small
    - `letters-numbers` sort letters first, then natural
    - `numbers-letters` sort natural numbers first, then letters
    - `chromosomes` sorts the given categories ascending by numbers and then x, y, mt (muation) */
  categoryOrder?: 'chromosomes' | 'given' | 'small-to-large' | 'large-to-small' | 'letters-numbers' | 'numbers-letters';
};
export type LinkColumn = {
  /** The name of the column in the database. */
  columnName: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** Multi-line description of the column. */
  description?: string;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** Default cell renderer to use */
  renderer?: ('image' | 'link') | 'string';
  /** Default group renderer to use */
  groupRenderer?: 'image' | 'link';
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?: 'default';
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Initial width of the column. */
  width?: number;
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  type?: 'link';
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** Replacement pattern: use `${value}` for the current value, `${escapedValue}` for an url safe value and `${item}` for the whole item. */
  pattern?: string;
  /** An optional list of pattern templates. */
  patternTemplates?: string[];
};
export type DataMap = {
  /** Type of the data mapping. */
  type?: 'linear' | 'log';
  /** Input range of the mapping. */
  domain?: number[];
  /** Output range of the mapping. */
  range?: number[];
};
export type NumericalColumn = {
  /** The name of the column in the database. */
  columnName: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** Multi-line description of the column. */
  description?: string;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** Default cell renderer to use */
  renderer?: 'default' | ('number' | 'brightness' | 'dot' | 'circle' | 'tick');
  /** Default group renderer to use */
  groupRenderer?: 'default' | ('boxplot' | 'histogram' | 'violin') | 'dot';
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?: 'default' | ('boxplot' | 'histogram' | 'violin');
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Initial width of the column. */
  width?: number;
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  type?: 'number';
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** Minimum value of the column. */
  min?: number;
  /** Maximum value of the column. */
  max?: number;
  /** Number format according to https://d3js.org/d3-format. */
  numberFormat?: string;
  /** The accuracy defines the deviation of values to the applied filter boundary. Use an accuracy closer to 0 for columns with smaller numbers (e.g., 1e-9). */
  filterAccuracy?: number;
  /** Whether to render a representation of the minimum value as some visual glyph, i.e. a bar with 1 px instead of 0 px. */
  showMinimumRepresentation?: boolean;
  /** Define the data mapping of the column to map the values to a linear or logarithmic scale and visualize them accordingly. */
  map?: DataMap;
};
export type SmilesColumn = {
  /** The name of the column in the database. */
  columnName: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** Multi-line description of the column. */
  description?: string;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** Default cell renderer */
  renderer?:
    | 'boolean'
    | 'boxplot'
    | 'brightness'
    | 'catdistributionbar'
    | 'categorical'
    | 'circle'
    | 'date'
    | 'default'
    | 'dot'
    | 'group'
    | 'heatmap'
    | 'catheatmap'
    | 'histogram'
    | 'datehistogram'
    | 'image'
    | 'interleaving'
    | 'link'
    | 'linkMap'
    | 'loading'
    | 'nested'
    | 'number'
    | 'mapbars'
    | 'rank'
    | 'selection'
    | 'set'
    | 'sparkline'
    | 'stack'
    | 'string'
    | 'stringhist'
    | 'table'
    | 'upset'
    | 'verticalbar'
    | 'violin'
    | 'tick'
    | 'cattick';
  /** Default group renderer */
  groupRenderer?:
    | 'boolean'
    | 'boxplot'
    | 'brightness'
    | 'catdistributionbar'
    | 'categorical'
    | 'circle'
    | 'date'
    | 'default'
    | 'dot'
    | 'group'
    | 'heatmap'
    | 'catheatmap'
    | 'histogram'
    | 'datehistogram'
    | 'image'
    | 'interleaving'
    | 'link'
    | 'linkMap'
    | 'loading'
    | 'nested'
    | 'number'
    | 'mapbars'
    | 'rank'
    | 'selection'
    | 'set'
    | 'sparkline'
    | 'stack'
    | 'string'
    | 'stringhist'
    | 'table'
    | 'upset'
    | 'verticalbar'
    | 'violin'
    | 'tick'
    | 'cattick';
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?:
    | 'boolean'
    | 'boxplot'
    | 'brightness'
    | 'catdistributionbar'
    | 'categorical'
    | 'circle'
    | 'date'
    | 'default'
    | 'dot'
    | 'group'
    | 'heatmap'
    | 'catheatmap'
    | 'histogram'
    | 'datehistogram'
    | 'image'
    | 'interleaving'
    | 'link'
    | 'linkMap'
    | 'loading'
    | 'nested'
    | 'number'
    | 'mapbars'
    | 'rank'
    | 'selection'
    | 'set'
    | 'sparkline'
    | 'stack'
    | 'string'
    | 'stringhist'
    | 'table'
    | 'upset'
    | 'verticalbar'
    | 'violin'
    | 'tick'
    | 'cattick';
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Initial width of the column. */
  width?: number;
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  type?: 'smiles';
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
};
export type StringColumn = {
  /** The name of the column in the database. */
  columnName: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** Multi-line description of the column. */
  description?: string;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** Default cell renderer to use */
  renderer?: 'default' | ('string' | 'stringhist');
  /** Default group renderer to use */
  groupRenderer?: 'default' | ('string' | 'stringhist');
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?: 'default' | ('string' | 'stringhist');
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Initial width of the column. */
  width?: number;
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  type?: 'string' | 'STRING';
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
};
export type NumberArrayColumn = {
  /** The name of the column in the database. */
  columnName: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** Multi-line description of the column. */
  description?: string;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** Default cell renderer to use */
  renderer?: 'default' | ('verticalbar' | 'boxplot' | 'dot' | 'heatmap' | 'histogram' | 'sparkline' | 'tick');
  /** Default group renderer to use */
  groupRenderer?: 'default' | ('boxplot' | 'dot' | 'heatmap' | 'histogram' | 'default' | 'sparkline' | 'violin');
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?: 'default' | ('boxplot' | 'histogram' | 'violin');
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Initial width of the column. */
  width?: number;
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  type?: 'numberArray';
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** Delimiter for the array column */
  delimiter?: ',' | ';' | '|';
};
export type StringArrayColumn = {
  /** The name of the column in the database. */
  columnName: string;
  /** Whether the column should be excluded from the frontend. This is useful for columns that are only used for internal purposes. */
  excluded?: boolean;
  /** Multi-line description of the column. */
  description?: string;
  /** Label of the column used in the user interface. If the label is not set, the column name is used. */
  label?: string;
  /** Whether to render the label as HTML (unsafe). */
  labelAsHTML?: boolean;
  /** Column summary line (subtitle). */
  summary?: string;
  /** Whether to render the summary as HTML (unsafe) or a custom function to convert a column to its summary label in HTML. */
  summaryAsHtml?: boolean;
  /** @experimental Whether the column is placed to the left side of the data table and remains fixed when scrolling horizontally. */
  frozen?: boolean;
  /** @experimental Whether the column can be removed or not. */
  fixed?: boolean;
  /** Default cell renderer to use */
  renderer?: 'default' | ('string' | 'stringhist');
  /** Default group renderer to use */
  groupRenderer?: 'default' | ('string' | 'stringhist');
  /** Default summary renderer in the header or filter sidebar. */
  summaryRenderer?:
    | 'boolean'
    | 'boxplot'
    | 'brightness'
    | 'catdistributionbar'
    | 'categorical'
    | 'circle'
    | 'date'
    | 'default'
    | 'dot'
    | 'group'
    | 'heatmap'
    | 'catheatmap'
    | 'histogram'
    | 'datehistogram'
    | 'image'
    | 'interleaving'
    | 'link'
    | 'linkMap'
    | 'loading'
    | 'nested'
    | 'number'
    | 'mapbars'
    | 'rank'
    | 'selection'
    | 'set'
    | 'sparkline'
    | 'stack'
    | 'string'
    | 'stringhist'
    | 'table'
    | 'upset'
    | 'verticalbar'
    | 'violin'
    | 'tick'
    | 'cattick';
  /** The icon for the column. Can be a font-awesome icon name. */
  icon?: string;
  /** Whether the column is visible in the initial ranking, i.e., when launching a new workbench with a data table view. */
  initialRanking?: boolean;
  /** Initial width of the column. */
  width?: number;
  /** The color for the column as HEX value. This might be overriden by the entity color or the renderer. */
  color?: string;
  type?: 'stringArray';
  /** Specifies the query categories applied by default */
  defaultQueryEnum?: (number | number | string | boolean | any[])[];
  /** `True` if the column is a primary key column for the entity. If multiple columns of the same entity are marked as primary, the primary key is a composite key (i.e., concatinated column names). */
  primary?: boolean;
  /** A global alias for this column, that you can use instead of db.table.schema.entity.column. */
  idtype?: string;
  /** True if the column is used to search for items in the entity. */
  searchable?: boolean;
  /** True if the column is used for a mapping drilldown. */
  mapping?: boolean;
  /** Determines the order of columns in the score column dialog and details sidebar. */
  order?: number;
  /** Delimiter for the array column */
  delimiter?: ',' | ';' | '|';
};
export type EntityUploadMetadata = {
  uid: string;
  metadata: {
    [key: string]: string;
  };
  size: number;
  offset?: number;
  upload_part?: number;
  created_at: string;
  defer_length: boolean;
  upload_chunk_size?: number;
  expires?: string;
  createdBy?: User;
  changedBy?: User;
  createdOn?: string;
  changedOn?: string;
};
export type UploadedEntityMetaResponse = {
  name: string;
  itemName?: string;
  idColumn?: string;
  description?: string;
  color: string;
  columns?: (BooleanColumn | CategoricalColumn | LinkColumn | NumericalColumn | SmilesColumn | StringColumn | NumberArrayColumn | StringArrayColumn)[];
  public?: boolean;
  collabs?: User[];
  id: string;
  createdBy: User;
  changedBy?: User;
  createdOn: string;
  changedOn?: string;
  fileMetadata?: EntityUploadMetadata;
  entityId: string;
};
export type UploadedEntityUpdate = {
  name: string;
  itemName?: string;
  description?: string;
  color: string;
  public?: boolean;
  columns?: (BooleanColumn | CategoricalColumn | LinkColumn | NumericalColumn | SmilesColumn | StringColumn | NumberArrayColumn | StringArrayColumn)[];
  collabs?: User[];
};
export type UploadedEntityCreate = {
  name: string;
  itemName?: string;
  idColumn?: string;
  description?: string;
  color: string;
  columns?: (BooleanColumn | CategoricalColumn | LinkColumn | NumericalColumn | SmilesColumn | StringColumn | NumberArrayColumn | StringArrayColumn)[];
  public?: boolean;
  collabs?: User[];
};
export type SubstructuresResponse = {
  count?: {
    [key: string]: number;
  };
  valid?: {
    [key: string]: boolean;
  };
};
export type AppConfigModel = {
  feedbackServiceEnabled?: boolean;
  insightManagementEnabled?: boolean;
  oncokbEnabled?: boolean;
  env?: 'development' | 'production';
  sentry_dsn?: string;
  sentry_proxy_to?: string;
};
export const {
  useGetLoginApiLoginGetQuery,
  useLazyGetLoginApiLoginGetQuery,
  usePostLoginApiLoginPostMutation,
  useLogoutApiLogoutPostMutation,
  useLoggedinasApiLoggedinasGetQuery,
  useLazyLoggedinasApiLoggedinasGetQuery,
  useLoggedinasApiLoggedinasPostMutation,
  useStoresApiSecurityStoresGetQuery,
  useLazyStoresApiSecurityStoresGetQuery,
  useListIdtypesApiIdtypeGetQuery,
  useLazyListIdtypesApiIdtypeGetQuery,
  useMapsToApiIdtypeIdtypeGetQuery,
  useLazyMapsToApiIdtypeIdtypeGetQuery,
  useMappingToApiIdtypeIdtypeToIdtypeGetQuery,
  useLazyMappingToApiIdtypeIdtypeToIdtypeGetQuery,
  useMappingToApiIdtypeIdtypeToIdtypePostMutation,
  useMappingToSearchApiIdtypeIdtypeToIdtypeSearchGetQuery,
  useLazyMappingToSearchApiIdtypeIdtypeToIdtypeSearchGetQuery,
  useGetPluginsApiTdpPluginGetQuery,
  useLazyGetPluginsApiTdpPluginGetQuery,
  useXlsx2JsonApiXlsxToJsonPostMutation,
  useXlsx2JsonArrayApiXlsxToJsonArrayPostMutation,
  useJson2XlsxApiXlsxFromJsonPostMutation,
  useJsonArray2XlsxApiXlsxFromJsonArrayPostMutation,
  useBuildInfoBuildInfoJsonGetQuery,
  useLazyBuildInfoBuildInfoJsonGetQuery,
  useHealthApiHealthGetQuery,
  useLazyHealthApiHealthGetQuery,
  useHealthApiHealthHeadMutation,
  useDeprecatedHealthHealthGetQuery,
  useLazyDeprecatedHealthHealthGetQuery,
  useDeprecatedHealthHealthHeadMutation,
  useSubscribeApiV1InsightSubscribeGetQuery,
  useLazySubscribeApiV1InsightSubscribeGetQuery,
  useGetCampaignsApiV1InsightCampaignsGetQuery,
  useLazyGetCampaignsApiV1InsightCampaignsGetQuery,
  useGetCampaignApiV1InsightCampaignIdGetQuery,
  useLazyGetCampaignApiV1InsightCampaignIdGetQuery,
  useDeleteCampaignApiV1InsightCampaignIdDeleteMutation,
  useUpdateCampaignApiV1InsightCampaignIdPatchMutation,
  useCreateCampaignApiV1InsightCampaignPostMutation,
  useAddCandidatesApiV1InsightCampaignIdAddCandidatesPostMutation,
  useGetAnswersApiV1InsightAnswerQuestionIdGetQuery,
  useLazyGetAnswersApiV1InsightAnswerQuestionIdGetQuery,
  useCreateAnswerApiV1InsightAnswerQuestionIdPostMutation,
  useGetAnswersForCampaignApiV1InsightAnswerCampaignCampaignIdGetQuery,
  useLazyGetAnswersForCampaignApiV1InsightAnswerCampaignCampaignIdGetQuery,
  useDeleteAnswerApiV1InsightAnswerAnswerIdDeleteMutation,
  useUpdateAnswerApiV1InsightAnswerAnswerIdPatchMutation,
  useUpdateFileApiV1InsightFileFileIdPatchMutation,
  usePossibleCandidatesApiV1InsightPossibleCandidatesCampaignIdGetQuery,
  useLazyPossibleCandidatesApiV1InsightPossibleCandidatesCampaignIdGetQuery,
  useParseFileApiV1InsightParseFilePostMutation,
  usePersistFileApiV1InsightPersistFilePostMutation,
  useGetFileApiV1InsightFileIdGetQuery,
  useLazyGetFileApiV1InsightFileIdGetQuery,
  useDeleteFileApiV1InsightFileIdDeleteMutation,
  useGetCandidateViewApiV1InsightCandidateViewIdGetQuery,
  useLazyGetCandidateViewApiV1InsightCandidateViewIdGetQuery,
  useGetLandscapesListApiLandscapesGetQuery,
  useLazyGetLandscapesListApiLandscapesGetQuery,
  useGetLandscapeApiLandscapesNameGetQuery,
  useLazyGetLandscapeApiLandscapesNameGetQuery,
  useUpdateLandscapeApiLandscapesNamePutMutation,
  useCreateLandscapeApiLandscapesNamePostMutation,
  useDeleteLandscapeApiLandscapesNameDeleteMutation,
  useValidateLandscapeApiLandscapesNameValidatePostMutation,
  useGetMatomoConfigApiMatomoGetQuery,
  useLazyGetMatomoConfigApiMatomoGetQuery,
  useGetUsersListApiAdminUsersGetQuery,
  useLazyGetUsersListApiAdminUsersGetQuery,
  useSearchUsersPaginatedApiAdminUsersSearchPostQuery,
  useLazySearchUsersPaginatedApiAdminUsersSearchPostQuery,
  useGetAnalysisSessionsListApiAnalysisSessionsListGetQuery,
  useLazyGetAnalysisSessionsListApiAnalysisSessionsListGetQuery,
  useGetAnalysisSessionApiAnalysisSessionsIdGetQuery,
  useLazyGetAnalysisSessionApiAnalysisSessionsIdGetQuery,
  useUpdateAnalysisSessionApiAnalysisSessionsIdPutMutation,
  useDeleteAnalysisSessionApiAnalysisSessionsIdDeleteMutation,
  useCreateAnalysisSessionApiAnalysisSessionsCreatePostMutation,
  useCheckSessionAvailabilityApiAnalysisSessionsCheckSessionAvailabilitySessionNameGetQuery,
  useLazyCheckSessionAvailabilityApiAnalysisSessionsCheckSessionAvailabilitySessionNameGetQuery,
  useGetUploadedEntityListApiUploadListGetQuery,
  useLazyGetUploadedEntityListApiUploadListGetQuery,
  useEntityPutApiUploadUuidPutMutation,
  useEntityPostApiUploadUuidPostMutation,
  useEntityDeleteApiUploadUuidDeleteMutation,
  useGetUploadApiFilesUuidGetQuery,
  useLazyGetUploadApiFilesUuidGetQuery,
  useDeleteUploadApiFilesUuidDeleteMutation,
  useOptionsUploadChunkApiFilesUuidOptionsMutation,
  useGetUploadMetadataApiFilesUuidHeadMutation,
  useUploadChunkApiFilesUuidPatchMutation,
  useCreateUploadApiFilesPostMutation,
  useOptionsCreateUploadApiFilesOptionsMutation,
  useGetUploadedFilesListMetadataApiFilesListMetadataGetQuery,
  useLazyGetUploadedFilesListMetadataApiFilesListMetadataGetQuery,
  useDrawSmilesApiRdkitGetQuery,
  useLazyDrawSmilesApiRdkitGetQuery,
  useMultipleImagesApiRdkitPostMutation,
  useDrawMurckoApiRdkitMurckoGetQuery,
  useLazyDrawMurckoApiRdkitMurckoGetQuery,
  useDrawMoleculeSimilarityApiRdkitSimilarityGetQuery,
  useLazyDrawMoleculeSimilarityApiRdkitSimilarityGetQuery,
  useDrawMaximumCommonSubstructureMoleculeApiRdkitMcsPostMutation,
  useSubstructuresCountApiRdkitSubstructuresPostMutation,
  useGetConfigPathApiTdpConfigPathGetQuery,
  useLazyGetConfigPathApiTdpConfigPathGetQuery,
  useProxySentryEnvelopeApiSentryPostMutation,
  useGetClientConfigApiV1VisynClientConfigGetQuery,
  useLazyGetClientConfigApiV1VisynClientConfigGetQuery,
} = injectedRtkApi;
